import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/michal/work/bite-api/frontend/dashboard/src/components/layout/dashboard.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Logging Using Text Search`}</h1>
    <p>{`To log a meal by text search requires the following steps.`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#search-items-by-text"
        }}>{`Search Items By Text`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/docs/food_logging/logging_a_meal_with_an_image/#create-a-meal"
        }}>{`Create a Meal`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/docs/food_logging/logging_a_meal_with_text_search/#adding-an-item-to-a-meal"
        }}>{`Adding an Item to a Meal`}</a></li>
    </ol>
    <h3>{`Search Items By Text`}</h3>
    <p>{`Searching by text requires a query. The query parameter executes a full text search. For exact
matching or brand search see the `}<a parentName="p" {...{
        "href": "https://api-beta.bite.ai/y7vtjyn4f64c/docs/reference/#operation/items_search_list"
      }}>{`query parameters for search`}</a>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` -X GET https://api-beta.bite.ai/items/search/?query`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`cheese`}</code></pre></div>
    <p>{`The response includes a list of items ranked.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"count"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`14846`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"next"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"https://api-beta.bite.ai/items/search/?limit=100&offset=100&query=cheese"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"previous"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"results"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"23c66800-5b53-443c-a464-2509d3bee813"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Cheese"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"brand"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"details"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"categorizations"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"is_generic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"62a21113-689b-49ca-a349-37315292df87"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Macaroni And Cheese"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"brand"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"details"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"categorizations"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"is_generic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`These results should be shown to the user. Nutrition facts and more specific subtypes about an
item can be retrieved by looking up `}<a parentName="p" {...{
        "href": "/docs/food_knowledge_base/retrieving_item_details/"
      }}>{`item details`}</a>{`.`}</p>
    <h3>{`Adding an Item to a Meal`}</h3>
    <p>{`Using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`meal_id`}</code>{` from the
`}<a parentName="p" {...{
        "href": "/docs/food_knowledge_base/retrieving_item_details/"
      }}>{`Create a Meal`}</a>{` operation and
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`item_id`}</code>{` an entry can be added using the`}<a parentName="p" {...{
        "href": "https://api-beta.bite.ai/y7vtjyn4f64c/docs/reference/#operation/meals_entries_create"
      }}>{` meal`}{`_`}{`entries`}{`_`}{`create`}</a>{`
operation.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` -X POST https://api-beta.bite.ai/meals/422d6199-c1b6-4037-866f-e28c1ea0b0ca/entries/ \\
    -d `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{
        "item": "23c66800-5b53-443c-a464-2509d3bee813",
        "serving_amount": 1,
        "nutrition_fact": "3599556d-fb5e-445b-b0fb-28842bc01e0d"
      }'`}</span></code></pre></div>
    <p>{`The item will be added to the meal.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"753b4dd1-e2f3-4521-a033-378a668c3482"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"item"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"serving_amount"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"nutrition_fact"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"3599556d-fb5e-445b-b0fb-28842bc01e0d"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"image"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"time_created"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"2018-03-27T05:12:51.019693Z"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"time_modified"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"2018-03-27T05:12:51.019963Z"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      